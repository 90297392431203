import axios from "axios";


const token = window.localStorage.getItem("token")??""
const client = axios.create({
    baseURL:process.env.REACT_APP_ENDPOINT,
    params:{token}
})

export default {
    getList:(limit,offset,time_before)=>client.get("/", {params:{
        limit,offset,time_before
        }}),
    decide:(id,approve,nsfw)=>client.get("/command/",{
        params:{id,approve,nsfw}
    })    ,
    author:(username)=>client.get("/author/",{
        params:{username}
    })
}
