export default ({titleContent, imageSize, children})=>{
    return <div>
        <div style={{position:"sticky",top:"0",width:"100%", background:"orange", height:"4rem", textAlign:"left", fontSize:"2.5rem",color:"white", zIndex:5}}>
            {titleContent}
        </div>
        <div style={{display:"grid",marginTop:"32px", flexWrap:"wrap",gridTemplateColumns: `repeat(auto-fill, minmax(${imageSize}px, 1fr))`, gap:"16px"}}>
            {children}
        </div>
        <div style={{height:"96px"}}></div>
    </div>
}
