export default ({approved, onCancel, onPass, onPassNsfw})=>{
    return approved?<div>
        <button style={{fontSize:"2rem"}}
                onClick={onCancel}
        >取消通过</button>
    </div>:<div>
        <button style={{fontSize:"2rem"}}
                onClick={onPass}
        >通过</button>
        <button style={{fontSize:"2rem", marginLeft:"32px"}}
                onClick={onPassNsfw}
        >NSFW</button>
    </div>
}
