import {useState} from "react";

export default ()=>{

    const [token,setToken] = useState(window.localStorage.getItem("token")??"")

    return<>
        <input value={token} onChange={({target:{value}})=>setToken(value)} />
        <button onClick={()=>{
            window.localStorage.setItem("token",token)
            window.location.reload()
        }}>login</button>
    </>
}
