import {Autocomplete, Box, Modal, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import client from "./client";

export default ({open, handleClose, handleSelect})=>{

    const [options, setOptions] = useState([])
    const [value, setValue] = useState("")

    useEffect(()=>{
        value&&get(value)
    }, [value])

    const get = async (username) =>{
        const {data} = await client.author(username)
        setOptions(data)
    }
    return <Modal
        open={open}
        onClose={handleClose}
    >
        <Box >
            <Autocomplete
                freeSolo
                options={options}
                getOptionLabel={(option)=>option.user_name}
                onChange={(event,option)=>{handleSelect(option)}}
                renderInput={(params) =>
                    <TextField onChange={(e)=>setValue(e.target.value)} value={value} {...params} label="freeSolo" />}
            />
        </Box>
    </Modal>
}
