import React from 'react'
import logo from './logo.svg';
import './App.css';
import Work from "./Work";
import {useState} from "react";
import Login from "./Login";
import {Button, Drawer} from "@mui/material";

function App() {

  return (
    <div className="App">
      {
        window.localStorage.getItem("token")?<Work />:<Login/>
      }
    </div>
  );
}

export default App;
