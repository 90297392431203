export default ({imageURL, onClose, children})=>{
    console.log(imageURL)
    return <div style={{
        zIndex:10,position:"fixed",width:"100%",
            height:"100vh", background:"#00000088",
            overflowY:"scroll", overscrollBehavior:"contain"}}>
        <div style={{ minHeight:"calc(100vh + 1px)"}}  onClick={onClose}>
            <img style={{maxWidth:"100%", maxHeight:"100vh"}} src={imageURL}/>
        </div>
        <div style={{position:"fixed", bottom:0, left:"50%", transform:"translateX(-50%)" }}>
            {children}
        </div>
    </div>

}
