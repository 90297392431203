import {useEffect, useRef, useState} from "react";
import client from "./client";
import Search from "./Search";
import ImageBlock from "./ImageBlock";
import ImageSection from "./ImageSection";
import Decide from "./Decide";
import ControlPanel from "./ControlPanel";

export default ()=>{

    const [searchOpen, setSearchOpen] = useState(false)

    const limit = useRef(30)
    const offset = useRef(0)
    const [timeBefore,setTimeBefore] = useState(new Date().getTime())
    const [data,setData] = useState([])
    const [loading,setLoading] = useState(false)
    const [selectedItem,setSelectedItem] = useState(null)
    const [showingLarge,setShowingLarge] = useState(false)
    const [largeMode, setLargeMode] = useState("height")
    const [lastTimeFailed, setLastTimeFailed] = useState(false)
    const [imageSize,setImageSize] = useState(parseInt(localStorage.getItem("image_size"))||200)
    const lastDate = useRef(-1)
    const ref = useRef()
    const isVisible = useOnScreen(ref)



    const dataCategorier=(newData)=>{
        if (!newData.length)return
        const d = [...data]
        for (const item of newData) {
            const thisDate = new Date(item.take_effect_time).toLocaleDateString()
            if (!d.length||new Date(d.at(-1)[0].take_effect_time).toLocaleDateString()!==thisDate){
                d.push([])
            }
            d.at(-1).push(item)
        }
        setData(d)

    }

    const get = async ()=>{
        try{
            setLastTimeFailed(false)
            const response = await client.getList(limit.current,offset.current,timeBefore)
            offset.current+=response.data.length
            dataCategorier(response.data)
            //TODO
            if (response.data.length===0){setLastTimeFailed(true)}
        }catch (e) {
            setLastTimeFailed(true)
        }finally {
            setLoading(false)
        }
    }

    useEffect(()=>{
        if (isVisible && !loading && !lastTimeFailed)
        {
            setLoading(true)
            get()
        }
    },[isVisible, loading])

    const isApproved=(n)=>!!(n&2)

    const decision = async (id,approve,nsfw)=>{
        await client.decide(id,approve,nsfw)
        if (approve)
            selectedItem.sent_flag |= 2
        else
            selectedItem.sent_flag ^= 2
        setData([...data])
        setSelectedItem(null)
        setLargeMode(false)
    }

    const sendingContent=(item)=>JSON.parse(atob(item.sending_content))
    const mediaUrl = (item)=>{
        return sendingContent(item)?.media?.url
    }
    return <>
        <Search open={searchOpen} handleClose={()=>{setSearchOpen(false)}}/>
        {showingLarge&&selectedItem&&<Decide imageURL={mediaUrl(selectedItem)+":large"} onClose={()=>{
            setSelectedItem(null)
            setShowingLarge(false)}}>
            <ControlPanel approved={isApproved(selectedItem.sent_flag)}
                          onCancel={()=>{decision(selectedItem.id, false,false)}}
                          onPass={()=>{decision(selectedItem.id, true,false)}}
                          onPassNsfw={()=>{decision(selectedItem.id, true,true)}}/>
        </Decide>}
        <div style={{position:"fixed",top:0, width:"100%", zIndex:8}}>
            {/*<input type="date" onChange={(e)=>setTimeBefore(new Date(e.target.value).getTime())}/>*/}
            {/*<button onClick={()=>{*/}
            {/*    setData([])*/}
            {/*    get()*/}
            {/*}} disabled={loading}>{loading?"耐心":"去"}</button>*/}
             <div style={{float:"right"}}>
                 <div>图片大小</div>
                 <input type="range" onChange={(e)=>{
                     setImageSize(Math.pow(e.target.value,4))
                     localStorage.setItem("image_size",Math.pow(e.target.value,4))
                 }} value={Math.pow(imageSize,0.25)} min={Math.pow(50,0.25)} max={Math.pow(window.innerWidth,0.25)} step={0.001} ></input>
             </div>
            {/*<button style={{float:"right"}} onClick={()=>localStorage.removeItem("token")}>不干了</button>*/}
        </div>
        {data.map((dateGroup,index)=>{
            return <ImageSection key={index} imageSize={imageSize} titleContent={new Date(dateGroup[0]?.take_effect_time).toLocaleDateString()}>
                {dateGroup.map((item)=>{
                    try{
                        const url = mediaUrl(item)
                        return url?<div key={item.id}>
                            <ImageBlock imageURL={url + ":small"} approved={isApproved(item.sent_flag)} onClick={() => {
                                setSelectedItem(item)
                                setShowingLarge(true)
                            }} imageSize={imageSize}></ImageBlock>
                        </div>:<></>
                    }catch (e) {
                        return <></>
                    }
                })}
            </ImageSection>

        })}
        <div ref={ref} style={{height:"200px"}}>{loading?"Loading":<button onClick={get}>load</button>}</div>

    </>
}

const useOnScreen=(ref)=> {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [])

    return isIntersecting
}
